import React, { useEffect } from "react";

const IndexPage = () => {
  useEffect(() => {
    window.location.replace("https://about.behavery.com/");
  });

  return "";
};

export default IndexPage;
